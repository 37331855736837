import { Button } from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';
import xlsx, { ColInfo, WorkSheet } from 'xlsx-js-style';
import { DateTime } from 'luxon';

const ACCOUNT_LIST_SHEET_TITLE = 'List of Accounts';
const DISCLOSURES_SHEET_TITLE = 'Disclosures';
const DATE_FORMAT = 'yyyy-MM-dd_hh-mm-ss-a';

const getColWidths = <T,>(data: T[], dataCustomHeaders: T | undefined, keys: (keyof T)[]): ColInfo[] => {
  return keys.map((key) => {
    const customHeaderWidth = dataCustomHeaders ? (dataCustomHeaders[key] as string).length : 0;
    const maxWidth = data.reduce((w, r) => {
      if (r[key]) {
        let valueLength = (r[key] as string).length;
        if (typeof r[key] === 'number') {
          valueLength = (r[key] as number).toString().length;
        }
        return Math.max(w, valueLength, (key as string).length, customHeaderWidth);
      }
      return Math.max((key as string).length, customHeaderWidth);
    }, 10);
    return {
      wch: maxWidth,
    };
  });
};

const getColHidden = <T,>(data: T[], hiddenColumns: (keyof T)[] | undefined): ColInfo[] => {
  if (data && data.length > 0) {
    const keys = Object.getOwnPropertyNames(data[0]);
    return keys.map((key) => {
      if (hiddenColumns && hiddenColumns.find(hc => hc.toString() == key)) {
        return { hidden: true }
      }
      else {
        return {}
      }
    });
  }
  else {
    return [];
  }
};

// A1:O17 => 17
const getMaxRow = (ref?: string): number | null => {
  const matches = ref?.split(':')[1].match('\\d+');
  if (matches) {
    return Number(matches[0]);
  }
  return null;
};

// A1:O17 => O
const getMaxColumn = (ref?: string): string | null => {
  const matches = ref?.split(':')[1].match('[A-Z]+');
  if (matches) {
    return matches[0];
  }
  return null;
};

const addBackgroundColor = (sheet: WorkSheet) => {
  const maxRow = getMaxRow(sheet['!ref']);
  const maxColumn = getMaxColumn(sheet['!ref']);

  // add #FFF2CC background color to all data cells
  // assumes top-left origin cell for data is "A7"
  const minCharCode = 'A'.charCodeAt(0);
  const maxCharCode = maxColumn?.charCodeAt(0);
  let currCharCode = minCharCode;
  if (maxCharCode && maxRow) {
    while (currCharCode <= maxCharCode) {
      for (let i = 7; i <= maxRow; i++) {
        const cellIndex = `${String.fromCharCode(currCharCode)}${i}`;
        if (sheet[cellIndex]) {
          sheet[cellIndex].s = {
            fill: { fgColor: { rgb: 'FFF2CC' } },
          };
        }
      }
      currCharCode += 1;
    }
  }

  return sheet;
};

const generateDataSheet = <T,>(
  data: T[],
  dataSheetTitle: string,
  columnsToAutoSize?: (keyof T)[],
  dataCustomHeaders?: T,
  columnsToHide?: (keyof T)[],
) => {
  const rows = [
    [
      {
        t: 's',
        v: `Summary of ${dataSheetTitle}`,
        s: { font: { bold: true } },
      },
    ],
    [{ t: 's', v: DateTime.now().toLocaleString(DateTime.DATETIME_FULL) }],
    [],
    [
      {
        t: 's',
        v: 'Consolidated Report [Click For List of Accounts Included]',
        l: { Target: `#'${ACCOUNT_LIST_SHEET_TITLE}'!A1` },
        s: { font: { bold: true, underline: true, color: { rgb: '0563C1' } } },
      },
    ],
    [
      {
        t: 's',
        v: 'Click for Disclosures',
        l: { Target: `#'${DISCLOSURES_SHEET_TITLE}'!A1` },
        s: { font: { underline: true, color: { rgb: '0563C1' } } },
      },
    ],
  ];
  const sheet = xlsx.utils.aoa_to_sheet(rows);
  xlsx.utils.sheet_add_json(sheet, data, { origin: 'A7' });
  if (data && data.length>0 && dataCustomHeaders) { 
    xlsx.utils.sheet_add_aoa(sheet, [Object.values(dataCustomHeaders).filter(dch => dch != '')], { origin: 'A7' });
  }
  const columnsHidden = getColHidden(data, columnsToHide);
  const columns = columnsHidden;
  if (columnsToAutoSize) {
    const columnWidths = getColWidths(data, dataCustomHeaders, columnsToAutoSize)
    for (let i = 0; i < columnsHidden.length; i++) {
      const columnHidden = columnsHidden[i];
      const columnWidth = columnWidths[i];
      columns[i] = { ...columnHidden, ...columnWidth };
    }
  }
  sheet['!cols'] = columns;
  
  addBackgroundColor(sheet);
  return sheet;
};

const generateAccountListSheet = (
  accounts: ExcelAccountItem[],
  columns: ExcelAccountListColumn[]
) => {
  const headerLabels = columns.map((column) => column.label);
  const keyList = columns.map((column) => column.key);
  const sheet = xlsx.utils.json_to_sheet(accounts);
  xlsx.utils.sheet_add_aoa(sheet, [headerLabels], { origin: 'A1' });
  sheet['!cols'] = getColWidths(accounts, undefined, keyList);

  let currCharCode = 65; // A
  while (currCharCode < columns.length + 65) {
    sheet[String.fromCharCode(currCharCode) + '1'].s = { font: { bold: true } };
    currCharCode += 1;
  }

  return sheet;
};

const generateDisclosuresSheet = (disclosuresTextLines: string[]) => {
  const rows = [
    [{ t: 's', v: 'Report Disclosures', s: { font: { bold: true } } }],
    ...disclosuresTextLines.map((lineText) => [
      {
        t: 's',
        v: lineText,
      },
    ]),
  ];
  return xlsx.utils.aoa_to_sheet(rows);
};

export interface ExcelAccountItem {
  accountNumber: string;
  accountName: string;
  accountBalance?: number;
  custodian: string;
}

interface ExportToExcelButtonProps<T> {
  data: T[];
  data2?: T[];
  data3?: T[];
  accounts: ExcelAccountItem[];
  accountListColumns: ExcelAccountListColumn[];
  filenamePrefix: string;
  dataSheetTitle: string;
  dataSheetTitle2?: string;
  dataSheetTitle3?: string;
  columnsToAutoSize?: (keyof T)[];
  disclosuresTextLines: string[];
  dataCustomHeaders?: T;
  dataCustomHeaders2?: T;
  dataCustomHeaders3?: T;
  tabName?: string;
  tabName2?: string;
  tabName3?: string;
  columnsToHide?: (keyof T)[];
  columnsToHide2?: (keyof T)[];
  columnsToHide3?: (keyof T)[];
}

interface ExcelAccountListColumn {
  key: keyof ExcelAccountItem;
  label: string;
}

export const ExportToExcelButton = <T,>({
  data,
  data2,
  data3,
  accounts,
  accountListColumns,
  filenamePrefix,
  dataSheetTitle,
  dataSheetTitle2,
  dataSheetTitle3,
  columnsToAutoSize,
  disclosuresTextLines,
  dataCustomHeaders,
  dataCustomHeaders2,
  dataCustomHeaders3,
  tabName,
  tabName2,
  tabName3,
  columnsToHide,
  columnsToHide2,
  columnsToHide3
}: ExportToExcelButtonProps<T>) => {
  const handleClick = () => {
    const wb = xlsx.utils.book_new();
    const dataSheet = generateDataSheet(
      data,
      dataSheetTitle,
      columnsToAutoSize,
      dataCustomHeaders,
      columnsToHide,
    );
    let dataSheet2 = null;
    if (data2) dataSheet2 = generateDataSheet(
      data2,
      dataSheetTitle2 ?? '',
      columnsToAutoSize,
      dataCustomHeaders2,
      columnsToHide2,
    );
    let dataSheet3 = null;
    if (data3) dataSheet3 = generateDataSheet(
      data3,
      dataSheetTitle3 ?? '',
      columnsToAutoSize,
      dataCustomHeaders3,
      columnsToHide3,
    );
    const accountSheet = generateAccountListSheet(accounts, accountListColumns);
    const disclosuresSheet = generateDisclosuresSheet(disclosuresTextLines);
    xlsx.utils.book_append_sheet(wb, dataSheet, tabName ?? dataSheetTitle);
    if (dataSheet2) xlsx.utils.book_append_sheet(wb, dataSheet2, tabName2 ?? dataSheetTitle2);
    if (dataSheet3) xlsx.utils.book_append_sheet(wb, dataSheet3, tabName3 ?? dataSheetTitle3);
    xlsx.utils.book_append_sheet(wb, accountSheet, ACCOUNT_LIST_SHEET_TITLE);
    xlsx.utils.book_append_sheet(wb, disclosuresSheet, DISCLOSURES_SHEET_TITLE);
    xlsx.writeFile(
      wb,
      `${filenamePrefix}-${DateTime.now().toFormat(DATE_FORMAT)}.xlsx`
    );
  };

  return (
    <Button
      sx={(theme) => ({
        padding: theme.spacing(1),
        width: theme.spacing(18),
        fontSize: '1.4rem',
        color: '#FFFFFF',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        '&:hover': {
          borderColor: '#FFFFFF',
        },
      })}
      variant='outlined'
      color='secondary'
      size='small'
      startIcon={<InsertDriveFile />}
      onClick={handleClick}
    >
      Export to XLS
    </Button>
  );
};

export default ExportToExcelButton;
