/* eslint-disable no-console */

declare global {
  namespace NodeJS {
    interface DeployEnv {}
  }
}

export interface AllowedClaims {
  extension_wmClientPortal?: boolean;
  extension_wmUserType?: 'Client' | 'Internal';
}

// Any change to the AppConfig type needs to be reflected in public_release/index.html as well as added to the release pipeline variables
export interface AppConfig {
  oidcRedirect: string;
  oidcAuthority: string;
  oidcTenant: string;
  oidcFlow: string;
  oidcClient: string;
  apiDomain: string;
  deployEnv: NodeJS.DeployEnv;
  appVersion: string; // Used in cache
  appName: string; // Used in cache
  appInsightsConnectionString?: string;
}

export interface Window {
  AppConfig: AppConfig;
}

import {
  PublicClientApplication,
  LogLevel,
  Configuration as MsalConfig,
  BrowserCacheLocation,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
const { userAgent } = window.navigator;
const msie = userAgent.indexOf('MSIE ');
const msie11 = userAgent.indexOf('Trident/');
const msedge = userAgent.indexOf('Edge/');
const firefox = userAgent.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// TODO: define logout flow. Right now all routes are authenticated, so it redirects to a login page.
const postLogoutPath = '/logged-out' as const;
const postLogoutRedirectUri = `${window.location.origin}${postLogoutPath}`;

export const createMsalClient = (
  appConfig: AppConfig
): PublicClientApplication => {
  const msalConfig: MsalConfig = {
    auth: {
      authority: `${appConfig.oidcAuthority}/${appConfig.oidcTenant}/${appConfig.oidcFlow}`,
      clientId: appConfig.oidcClient,
      redirectUri: appConfig.oidcRedirect,
      knownAuthorities: [appConfig.oidcAuthority],
      postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
      secureCookies: true,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string): void => {
          switch (level) {
            case LogLevel.Error:
              console.error('[MSAL]', message);
              return;
            case LogLevel.Info:
              console.info('[MSAL]', message);
              return;
            case LogLevel.Verbose:
              console.debug('[MSAL]', message);
              return;
            case LogLevel.Warning:
              console.warn('[MSAL]', message);
              break;
            default:
          }
        },
        piiLoggingEnabled: false,
      },
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 0,
      asyncPopups: false,
    },
  };

  return new PublicClientApplication(msalConfig);
};

export const getAccessScopes = (appConfig: AppConfig) => {
  return {
    openid: 'openid',
    profile: 'profile',
    gatewayApi: `https://${appConfig.oidcTenant}/wm-gql-gateway/read`,
  } as const;
};

export const createAccessTokenFn =
  (client: PublicClientApplication) => async (scopes: string[]) => {
    try {
      const accessTokenResponse = await client.acquireTokenSilent({
        scopes,
      });

      if (accessTokenResponse.accessToken === '') {
        throw new InteractionRequiredAuthError();
      }
      return accessTokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await client.acquireTokenRedirect({
          scopes,
        });
      }
    }
    return '';
  };

export const initAuth = (appConfig: AppConfig) => {
  const msalClient = createMsalClient(appConfig);
  const accessScopes = getAccessScopes(appConfig);
  const getAccessToken = createAccessTokenFn(msalClient);
  return { msalClient, accessScopes, getAccessToken };
};
