import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';

interface MobileReportTableHeaderProps<T> {
  selectReport: (selectedReport: T) => void;
  defaultValue?: string;
  selectItems: { value: string; label: string }[];
}

export const MobileSelectHeader = <T,>({
  selectReport,
  defaultValue,
  selectItems,
}: MobileReportTableHeaderProps<T>) => {
  const [selectedIndex, setSelectedIndex] = useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedIndex(event.target.value as string);
    selectReport(event.target.value as T);
  };

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        backgroundColor: '#505050',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        ...theme.typography.body1,
      })}
    >
      {'View By'}
      <Select
        IconComponent={() => (
          <ArrowForwardIosIcon
            sx={{
              transform: 'rotate(90deg)',
              marginRight: '1rem',
            }}
          ></ArrowForwardIosIcon>
        )}
        value={selectedIndex === '' ? defaultValue : selectedIndex}
        onChange={handleChange}
        label={'Account Groups'}
        sx={(theme) => ({
          width: '55%',
          height: '2.5rem',
          borderRadius: '7px',
          float: 'right',
          backgroundColor: theme.palette.background.paper,
          border: '0px',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            display: 'none',
          },
        })}
      >
        {selectItems.map((item) => {
          return (
            <MenuItem sx={{ marginLeft: '3px' }} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default MobileSelectHeader;
