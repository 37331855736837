import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import {
  HouseholdTableRowData,
  PerformanceModel,
  PerformanceNestedTableRowData,
  PerformanceTableRowData,
} from '../@types';
import PerformanceTableRow from './PerformanceTableRow';
import { useState } from 'react';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ColumnVisibilityMenu, {
  ColumnVisibilityState,
} from './ColumnVisibilityMenu';
import { Order, getDefaultComparator, stableSort } from '@newedge/common';
import { PerformanceTableColumn } from './@types';
import { HouseholdTableRow } from './HouseholdTableRow';

const getUngroupedAccountsAtEndComparator = (
  order: Order,
  orderBy: keyof Omit<PerformanceTableRowData, 'nestedData'>
) => {
  return (a: PerformanceTableRowData, b: PerformanceTableRowData) => {
    if (a.accountGroupId === '0' && orderBy === 'name') {
      return 1;
    }
    if (b.accountGroupId === '0' && orderBy === 'name') {
      return -1;
    }

    return getDefaultComparator<
      keyof Omit<PerformanceTableRowData, 'nestedData'>
    >(order, orderBy)(a, b);
  };
};

const headCells: PerformanceTableColumn[] = [
  {
    sortProperty: 'name',
    label: 'Name',
    alwaysVisible: true,
  },
  {
    sortProperty: 'marketValue',
    label: 'Market Value',
    alwaysVisible: true,
  },
  {
    sortProperty: 'monthToDate',
    label: 'MTD',
  },
  { sortProperty: 'quarterToDate', label: 'QTD' },
  {
    sortProperty: 'yearToDate',
    label: 'YTD',
  },
  {
    sortProperty: 'oneYearReturns',
    label: '1YR',
  },
  {
    sortProperty: 'threeYearReturns',
    label: '3YR',
  },
  {
    sortProperty: 'fiveYearReturns',
    label: '5YR',
  },
  {
    sortProperty: 'tenYearReturns',
    label: '10YR',
  },
  {
    sortProperty: 'sinceInception',
    label: 'Since Inception',
  },
  {
    sortProperty: 'inceptionDate',
    label: 'Inception Date',
    alwaysVisible: true,
  },
];

type PerformanceTableProps = {
  data: PerformanceTableRowData[] | HouseholdTableRowData[];
  setHeadCellWidths: React.Dispatch<React.SetStateAction<{}>>;
  byHousehold?: boolean;
};

export function PerformanceTable({
  data,
  setHeadCellWidths,
  byHousehold = true,
}: PerformanceTableProps) {
  const [columnVisibilityState, setColumnVisibilityState] = useState<
    ColumnVisibilityState[]
  >(
    headCells
      .filter((headCell) => !headCell.alwaysVisible)
      .map((headCell) => {
        return {
          label: headCell.label,
          visible: true,
        };
      })
  );

  const [order, setOrder] = React.useState<Order>(Order.Asc);
  const [orderBy, setOrderBy] = React.useState<keyof PerformanceModel>('name');

  const setSort = (fieldToSort: keyof PerformanceModel) => {
    if (fieldToSort === orderBy) {
      setOrder(order === Order.Asc ? Order.Desc : Order.Asc);
    } else {
      setOrder(Order.Asc);
    }
    setOrderBy(fieldToSort);
  };

  const nonHouseholdSort = (
    array: PerformanceTableRowData[] | undefined
  ): PerformanceTableRowData[] => {
    if (!array) {
      return [];
    }

    array.forEach((rowData) => {
      rowData.nestedData =
        rowData.nestedData &&
        stableSort<PerformanceNestedTableRowData>(
          rowData.nestedData,
          getDefaultComparator(order, orderBy)
        );
    });

    return stableSort<PerformanceTableRowData>(
      array,
      getUngroupedAccountsAtEndComparator(
        order,
        orderBy as keyof Omit<PerformanceTableRowData, 'nestedData'>
      )
    );
  };

  const sort = (array: HouseholdTableRowData[]): HouseholdTableRowData[] => {
    if (!array) {
      return [];
    }

    return stableSort<HouseholdTableRowData>(
      array,
      getDefaultComparator(order, orderBy)
    );
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '40rem' }}>
      <Table
        stickyHeader
        sx={{ minWidth: '1120px', borderCollapse: 'collapse' }}
      >
        <TableHead sx={(theme) => ({ ...theme.typography.tableHeaderRow })}>
          <TableRow
            sx={{
              height: '6.5rem',
            }}
          >
            {headCells.map((headCell, index) => {
              return (
                <TableCell
                  ref={(node: HTMLTableCellElement) => {
                    if (node) {
                      setHeadCellWidths(
                        (headCellWidths: { [index: number]: number }) => {
                          if (headCellWidths[index] !== node.offsetWidth) {
                            return {
                              ...headCellWidths,
                              [index]: node.offsetWidth,
                            };
                          }
                          return headCellWidths;
                        }
                      );
                    }
                  }}
                  sortDirection={
                    headCell.sortProperty === orderBy ? order : false
                  }
                  sx={{
                    fontSize: '1.6rem',
                    backgroundColor: 'greyPercent.20',
                    textAlign: headCell.label === 'Name' ? 'left' : 'right',
                    paddingLeft: headCell.label === 'Name' ? '2.9em' : 'auto',
                    zIndex: headCell.label === 'Market Value' ? 2 : 1,
                  }}
                  key={`HeaderCell_${headCell.sortProperty}`}
                >
                  {
                    <TableSortLabel
                      active={headCell.sortProperty === orderBy}
                      direction={
                        headCell.sortProperty === orderBy ? order : Order.Asc
                      }
                      onClick={() => setSort(headCell.sortProperty)}
                      IconComponent={ArrowDropDownIcon}
                      sx={{
                        flexDirection:
                          headCell.label === 'Name' ? 'row' : 'row-reverse',
                        visibility:
                          columnVisibilityState.find(
                            (value) => value.label === headCell.label
                          )?.visible === false
                            ? 'hidden'
                            : 'visible',
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  }
                  {headCell.label === 'Market Value' ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        right: '-2.8rem',
                        bottom: '1.6rem',
                      }}
                    >
                      <ColumnVisibilityMenu
                        columnState={columnVisibilityState}
                        setColumnState={setColumnVisibilityState}
                      />
                    </Box>
                  ) : null}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {byHousehold
            ? sort(data).map((rowData) => {
                return (
                  <HouseholdTableRow
                    key={rowData.uniqueId}
                    rowData={rowData}
                    visibilityState={columnVisibilityState}
                    order={order}
                    orderBy={orderBy}
                  />
                );
              })
            : nonHouseholdSort(data).map((rowData) => {
                let startOpen = false;
                return (
                  <PerformanceTableRow
                    key={rowData.name}
                    rowData={rowData}
                    startOpen={startOpen}
                    isOpen
                    visibilityState={columnVisibilityState}
                  />
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PerformanceTable;
