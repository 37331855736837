import {
  ColumnDataType,
  MobileReportTable,
  MobileReportTableColumn,
  MobileSelectHeader,
  NumberFormatType,
  RefineYourReportButton,
  ValueOf,
  formatNumber,
} from '@newedge/common';
import {
  HouseholdTableRowData,
  OrionApiPerformanceStatusData,
  PerformanceNestedTableRowData,
  PerformanceTableRowData,
} from './@types';
import { Box } from '@mui/material';
import { AsOfDate } from '../../components';
import {
  AcctGroupsMobileTable,
  AssetClassMobileTable,
  HoldingsMobileTable,
  ManagementStyleMobileTable,
  ReportTypes,
  SubAssetClassMobileTable,
} from './performance-tabs';
import { RefObject, useEffect, useState } from 'react';

const mobileCustomValueRenderer = (
  value: ValueOf<PerformanceNestedTableRowData>
) => {
  return value === null
    ? '--'
    : formatNumber(value as number, NumberFormatType.Percent, 2, 2);
};

const mobileBenchmarkColumns: MobileReportTableColumn<PerformanceNestedTableRowData>[] =
  [
    {
      id: 'name',
      label: 'Name',
      dataType: ColumnDataType.String,
      isIgnorableColumn: true,
    },
    {
      id: 'monthToDate',
      label: 'MTD',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => mobileCustomValueRenderer(value),
    },
    {
      id: 'quarterToDate',
      label: 'QTD',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => mobileCustomValueRenderer(value),
    },
    {
      id: 'yearToDate',
      label: 'YTD',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => mobileCustomValueRenderer(value),
    },
    {
      id: 'oneYearReturns',
      label: '1YR',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => mobileCustomValueRenderer(value),
    },
    {
      id: 'threeYearReturns',
      label: '3YR',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => mobileCustomValueRenderer(value),
    },
    {
      id: 'fiveYearReturns',
      label: '5YR',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => mobileCustomValueRenderer(value),
    },
    {
      id: 'tenYearReturns',
      label: '10YR',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => mobileCustomValueRenderer(value),
    },
  ];

const selectItems: { value: string; label: string }[] = [
  { value: ReportTypes.AccountGroups, label: 'Acct Groups' },
  { value: ReportTypes.ManagementStyle, label: 'Mgmt Style' },
  { value: ReportTypes.AssetClass, label: 'Asset Class' },
  { value: ReportTypes.SubAssetClass, label: 'Sub-Asset Class' },
  { value: ReportTypes.Holdings, label: 'Holdings' },
];

const renderComingSoon = () => {
  return <Box sx={{ paddingTop: 3 }}>Coming Soon</Box>;
};

interface PerformanceMobileViewProps {
  orionApiServiceData: OrionApiPerformanceStatusData;
  accountGroupPerformanceData: PerformanceTableRowData[];
  assetClassPerformanceData: HouseholdTableRowData[];
  subAssetClassPerformanceData: HouseholdTableRowData[];
  benchmarkAsOfDate: any;
  benchmarkData: PerformanceNestedTableRowData[];
  holdingsPerformanceData: HouseholdTableRowData[];
  managementStylePerformanceData: HouseholdTableRowData[];
  tableFilterRef: RefObject<HTMLDivElement & { expandAccordition: () => void }>;
}

export const PerformanceMobileView = ({
  orionApiServiceData,
  accountGroupPerformanceData,
  assetClassPerformanceData,
  subAssetClassPerformanceData,
  benchmarkAsOfDate,
  benchmarkData,
  holdingsPerformanceData,
  managementStylePerformanceData,
  tableFilterRef,
}: PerformanceMobileViewProps) => {
  const [selectMobileReport, setSelectMobileReport] = useState<ReportTypes>(
    ReportTypes.AccountGroups
  );

  const renderMobilePerformanceReport = () => {
    switch (selectMobileReport) {
      case ReportTypes.AccountGroups:
        return (
          <AcctGroupsMobileTable
            status={orionApiServiceData.accountGroups}
            accountGroupPerformanceData={accountGroupPerformanceData}
          />
        );
      case ReportTypes.ManagementStyle:
        return (
          <ManagementStyleMobileTable
            managementStylePerformanceData={managementStylePerformanceData}
          />
        );
      case ReportTypes.AssetClass:
        return (
          <AssetClassMobileTable
            assetClassPerformanceData={assetClassPerformanceData}
          />
        );
      case ReportTypes.SubAssetClass:
        return (
          <SubAssetClassMobileTable
            subAssetClassPerformanceData={subAssetClassPerformanceData}
          />
        );
      case ReportTypes.Holdings:
        return (
          <HoldingsMobileTable
            holdingsPerformanceData={holdingsPerformanceData}
          />
        );
      default:
        break;
    }
  };

  const renderMobileBenchmarkReport = () => {
    return (
      <MobileReportTable
        zebra
        columns={mobileBenchmarkColumns}
        data={benchmarkData}
        headerId='name'
        title='Benchmark'
      />
    );
  };

  const handlerScrollToTop = () => {
    tableFilterRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    tableFilterRef.current?.expandAccordition();
  };

  const [asOfDate, setAsOfDate] = useState<string | null>();

  useEffect(() => {
    if (selectMobileReport === ReportTypes.AccountGroups) {
      accountGroupPerformanceData.length > 0
        ? setAsOfDate(accountGroupPerformanceData[0].asOfDate)
        : null;
    }
    if (selectMobileReport === ReportTypes.ManagementStyle) {
      managementStylePerformanceData.length > 0
        ? setAsOfDate(managementStylePerformanceData[0].asOfDate)
        : null;
    }
    if (selectMobileReport === ReportTypes.AssetClass) {
      assetClassPerformanceData.length > 0
        ? setAsOfDate(assetClassPerformanceData[0].asOfDate)
        : null;
    }
    if (selectMobileReport === ReportTypes.SubAssetClass) {
      subAssetClassPerformanceData.length > 0
        ? setAsOfDate(subAssetClassPerformanceData[0].asOfDate)
        : null;
    }
    if (selectMobileReport === ReportTypes.Holdings) {
      holdingsPerformanceData.length > 0
        ? setAsOfDate(holdingsPerformanceData[0].asOfDate)
        : null;
    }
  }, [
    selectMobileReport,
    accountGroupPerformanceData,
    assetClassPerformanceData,
    subAssetClassPerformanceData,
  ]);

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <AsOfDate asOfDate={asOfDate} />
      </Box>
      <MobileSelectHeader
        selectItems={selectItems}
        selectReport={setSelectMobileReport}
        defaultValue={ReportTypes.AccountGroups}
      />
      {renderMobilePerformanceReport()}
      <Box sx={{ textAlign: 'left' }}>
        <AsOfDate asOfDate={benchmarkAsOfDate} />
      </Box>
      {renderMobileBenchmarkReport()}
      <RefineYourReportButton onClick={handlerScrollToTop} />
    </>
  );
};
