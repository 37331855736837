import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Popover,
  Switch,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { PerformanceTableColumn } from './@types';

export interface ColumnVisibilityState {
  label: PerformanceTableColumn['label'];
  visible: boolean;
}

interface ColumnVisibilityMenuProps {
  columnState: ColumnVisibilityState[];
  setColumnState: (state: ColumnVisibilityState[]) => void;
}

export const ColumnVisibilityMenu = ({
  columnState,
  setColumnState,
}: ColumnVisibilityMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'performance-column-visibility-popover' : undefined;

  const getOnChangeHandler = (label: string) => {
    return () => {
      setColumnState(
        columnState.map((column) => {
          return {
            ...column,
            visible: column.label === label ? !column.visible : column.visible,
          };
        })
      );
    };
  };

  return (
    <>
      <IconButton
        aria-controls="performance-column-visibility"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon fontSize="large" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -200,
        }}
      >
        <Paper sx={{ px: 3, py: 1.5 }}>
          <FormGroup>
            {columnState.map((column) => {
              return (
                <FormControlLabel
                  key={column.label}
                  control={<Switch checked={column.visible} size="small" />}
                  label={column.label}
                  onChange={getOnChangeHandler(column.label)}
                />
              );
            })}
          </FormGroup>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 1,
            }}
          >
            <Button
              size="small"
              onClick={() => {
                setColumnState(
                  columnState.map((column) => {
                    return {
                      ...column,
                      visible: true,
                    };
                  })
                );
              }}
            >
              Show all
            </Button>
            <Button
              size="small"
              onClick={() => {
                setColumnState(
                  columnState.map((column) => {
                    return {
                      ...column,
                      visible: false,
                    };
                  })
                );
              }}
            >
              Hide all
            </Button>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default ColumnVisibilityMenu;
