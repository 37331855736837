import { Grid, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useRef } from 'react';
import {
  HouseholdTableRowData,
  OrionApiPerformanceStatusData,
  PerformanceNestedTableRowData,
  PerformanceTableRowData,
} from './@types';
import _ from 'lodash';
import { ClientPortalFinancialAccount } from '@newedge/common';
import { PerformanceMobileView } from './PerformanceMobileView';
import { PerformanceDesktopView } from './PerformanceDesktopView';

type PerformanceViewProps = {
  orionApiServiceData: OrionApiPerformanceStatusData;
  benchmarkData: PerformanceNestedTableRowData[];
  benchmarkAsOfDate: any;
  accounts: ClientPortalFinancialAccount[];
  selectedAccounts: number[];
  accountGroupPerformanceData: PerformanceTableRowData[];
  performanceByAssetClassData: HouseholdTableRowData[];
  performanceBySubAssetClassData: HouseholdTableRowData[];
  performanceByHoldingsData: HouseholdTableRowData[];
  performanceByManagementStyleData: HouseholdTableRowData[];
};

export const PerformanceView = ({
  orionApiServiceData,
  benchmarkData,
  benchmarkAsOfDate,
  accounts,
  selectedAccounts,
  accountGroupPerformanceData,
  performanceByAssetClassData,
  performanceBySubAssetClassData,
  performanceByHoldingsData,
  performanceByManagementStyleData,
}: PerformanceViewProps) => {
  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const renderRef = useRef<HTMLDivElement & { expandAccordition: () => void }>(
    null
  );

  accountGroupPerformanceData.sort((a, b) => {
    if (b.name! > a.name!) {
      return -1;
    }
    if (b.name! < a.name!) {
      return 1;
    }
    return 0;
  });

  accountGroupPerformanceData.forEach((x) => {
    return x.nestedData?.sort((a, b) => {
      if (b.name! > a.name!) {
        return -1;
      }
      if (b.name! < a.name!) {
        return 1;
      }
      return 0;
    });
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={isMobileOrTablet ? 12 : 8} />
      {isMobileOrTablet ? (
        <Grid item>
          <PerformanceMobileView
            orionApiServiceData={orionApiServiceData}
            accountGroupPerformanceData={accountGroupPerformanceData}
            assetClassPerformanceData={performanceByAssetClassData}
            subAssetClassPerformanceData={performanceBySubAssetClassData}
            benchmarkAsOfDate={benchmarkAsOfDate}
            benchmarkData={benchmarkData}
            holdingsPerformanceData={performanceByHoldingsData}
            managementStylePerformanceData={performanceByManagementStyleData}
            tableFilterRef={renderRef}
          />
        </Grid>
      ) : (
        <PerformanceDesktopView
          orionApiServiceData={orionApiServiceData}
          accountGroupPerformanceData={accountGroupPerformanceData}
          assetClassPerformanceData={performanceByAssetClassData}
          subAssetClassPerformanceData={performanceBySubAssetClassData}
          benchmarkAsOfDate={benchmarkAsOfDate}
          benchmarkData={benchmarkData}
          holdingsPerformanceData={performanceByHoldingsData}
          managementStylePerformanceData={performanceByManagementStyleData}
          accounts={accounts}
          selectedAccounts={selectedAccounts}
        />
      )}
    </Grid>
  );
};

export default PerformanceView;
