import { MobileReportTable } from '@newedge/common';
import { HouseholdTableRowData } from '../@types';
import PerformanceTable from '../performance-table/PerformanceTable';
import { mobileReportColumns } from '.';

interface AssetClassPanelContentProps {
  assetClassPerformanceData: HouseholdTableRowData[];
  setHeadCellWidths: React.Dispatch<React.SetStateAction<{}>>;
}

export const AssetClassPanelContent = ({
  assetClassPerformanceData,
  setHeadCellWidths,
}: AssetClassPanelContentProps) => {
  return (
    <PerformanceTable
      data={assetClassPerformanceData}
      setHeadCellWidths={setHeadCellWidths}
    />
  );
};

interface AssetClassMobileTableProps {
  assetClassPerformanceData: HouseholdTableRowData[];
}

export const AssetClassMobileTable = ({
  assetClassPerformanceData,
}: AssetClassMobileTableProps) => {
  return (
    <MobileReportTable
      columns={mobileReportColumns}
      data={assetClassPerformanceData}
      headerId='name'
      nestedHeaderId='name'
      nestedAccessor={(rowData) => rowData.nestedData!}
      customTileListRenderer={(nestedData) => {
        return (
          <MobileReportTable 
            columns={mobileReportColumns}
            data={nestedData}
            headerId='name'
            nestedHeaderId='name'
            nestedAccessor={(rowData) => rowData.nestedData!}
          />
        )
      }}
      hasParentHeaders={false}
      positiveNumberDecorator={true}
    />
  );
};
