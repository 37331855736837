import { MobileReportTable, TooltipWrapper } from '@newedge/common';
import { HouseholdTableRowData } from '../@types';
import PerformanceTable from '../performance-table/PerformanceTable';
import { mobileReportColumns } from '.';
import { Box } from '@mui/material';

interface ManagementStylePanelContentProps {
  managementStylePerformanceData: HouseholdTableRowData[];
  setHeadCellWidths: React.Dispatch<React.SetStateAction<{}>>;
}

export const ManagementStylePanelContent = ({
  managementStylePerformanceData,
  setHeadCellWidths,
}: ManagementStylePanelContentProps) => {
  return (
    <PerformanceTable
      data={managementStylePerformanceData}
      setHeadCellWidths={setHeadCellWidths}
    />
  );
};

interface ManagementStyleMobileTableProps {
  managementStylePerformanceData: HouseholdTableRowData[];
}

export const ManagementStyleMobileTable = ({
  managementStylePerformanceData,
}: ManagementStyleMobileTableProps) => {
  return (
    <MobileReportTable
      columns={mobileReportColumns}
      data={managementStylePerformanceData}
      headerId='name'
      nestedHeaderId='name'
      nestedAccessor={(rowData) => rowData.nestedData!}
      customTileListRenderer={(nestedData) => {
        return (
          <MobileReportTable
            columns={mobileReportColumns}
            data={nestedData}
            headerId='name'
            nestedHeaderId='name'
            nestedAccessor={(rowData) => rowData.nestedData!}
            customNestedHeaderRenderer={(row, nestedRow) => {
              return (
                <Box
                  sx={(theme) => ({
                    fontSize: '1.4rem',
                    borderBottom: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
                    paddingBottom: theme.spacing(0.5),
                    marginBottom: theme.spacing(1),
                    display: 'flex',
                    justifyContent: 'space-between',
                  })}
                >
                  <TooltipWrapper
                    text={nestedRow.name}
                    maxLength={72}
                    typographyVariant='link'
                  />
                </Box>
              );
            }}
          />
        );
      }}
      hasParentHeaders={false}
      positiveNumberDecorator={true}
    />
  );
};
