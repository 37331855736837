import { useCallback, useState } from 'react';
import {
  HouseholdTableRowData,
  PerformanceModel,
  PerformanceNestedTableRowData,
  PerformanceTableRowData,
} from '../@types';
import { ColumnVisibilityState } from './ColumnVisibilityMenu';
import { PerformanceTableColumn } from './@types';
import { cellValueFormatter } from '../performanceHelpers';
import PerformanceTableRow from './PerformanceTableRow';
import {
  NumberFormatType,
  Order,
  formatNumber,
  getDefaultComparator,
  stableSort,
} from '@newedge/common';
import { IconButton, TableCell, TableRow, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const getUngroupedAccountsAtEndComparator = (
  order: Order,
  orderBy: keyof Omit<
    PerformanceTableRowData,
    | 'nestedData'
    | 'marketValue'
    | 'monthToDate'
    | 'quarterToDate'
    | 'yearToDate'
    | 'oneYearReturns'
    | 'threeYearReturns'
    | 'fiveYearReturns'
    | 'tenYearReturns'
    | 'sinceInception'
    | 'inceptionDate'
    | 'accountGroupId'
    | 'asOfDate'
  >
) => {
  return (a: PerformanceTableRowData, b: PerformanceTableRowData) => {
    if (a.accountGroupId === '0') {
      return 1;
    }
    if (b.accountGroupId === '0') {
      return -1;
    }

    return getDefaultComparator<
      keyof Omit<
        PerformanceTableRowData,
        | 'nestedData'
        | 'marketValue'
        | 'monthToDate'
        | 'quarterToDate'
        | 'yearToDate'
        | 'oneYearReturns'
        | 'threeYearReturns'
        | 'fiveYearReturns'
        | 'tenYearReturns'
        | 'sinceInception'
        | 'inceptionDate'
        | 'accountGroupId'
        | 'asOfDate'
      >
    >(order, orderBy)(a, b);
  };
};

const StyledTableCell = styled(TableCell)(({ theme: { extensions } }) => ({
  fontSize: '1.4rem',
  textAlign: 'right',
  backgroundColor: extensions.grey[8],
}));

const StyledInceptionTableCell = styled(TableCell)(
  ({ theme: { extensions, typography, spacing } }) => ({
    ...typography.link,
    backgroundColor: extensions.grey[8],
  })
);

interface HouseholdTableRowProps {
  rowData: HouseholdTableRowData;
  visibilityState: ColumnVisibilityState[];
  order: Order;
  orderBy: keyof PerformanceModel;
}

export const HouseholdTableRow = ({
  rowData: data,
  visibilityState,
  order,
  orderBy,
}: HouseholdTableRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderCellValue = useCallback(
    (
      label: PerformanceTableColumn['label'],
      key: keyof Omit<HouseholdTableRowData, 'nestedData'>
    ) => {
      if (
        visibilityState.find((column) => column.label === label)?.visible ===
        false
      ) {
        return null;
      }
      return cellValueFormatter(data[key]);
    },
    [data, visibilityState]
  );

  const sort = (
    array: PerformanceTableRowData[] | undefined
  ): PerformanceTableRowData[] => {
    if (!array) {
      return [];
    }

    let accountGroupSortProperty = orderBy;
    let accountSortProperty = orderBy;

    array.forEach((rowData) => {
      rowData.nestedData =
        rowData.nestedData &&
        stableSort<PerformanceNestedTableRowData>(
          rowData.nestedData,
          getDefaultComparator(order, accountSortProperty) as any
        );
    });

    let groupOrder = order;

    return stableSort<PerformanceTableRowData>(
      array,
      getUngroupedAccountsAtEndComparator(
        groupOrder,
        accountGroupSortProperty as keyof Omit<
          PerformanceTableRowData,
          | 'nestedData'
          | 'marketValue'
          | 'monthToDate'
          | 'quarterToDate'
          | 'yearToDate'
          | 'oneYearReturns'
          | 'threeYearReturns'
          | 'fiveYearReturns'
          | 'tenYearReturns'
          | 'sinceInception'
          | 'inceptionDate'
          | 'accountGroupId'
          | 'asOfDate'
        >
      )
    );
  };

  return (
    <>
      <TableRow sx={(theme) => ({ border: theme.extensions.borders.grey28 })}>
        <TableCell
          sx={(theme) => ({
            ...theme.typography.caption,
            backgroundColor: theme.extensions.grey[8],
            py: 2,
            paddingLeft: 0,
            paddingRight: 4,
          })}
          component='th'
          scope='row'
        >
          {data.nestedData && (
            <IconButton
              aria-label='expand row'
              sx={{ fontSize: '2.4rem', padding: '3px' }}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              size='large'
            >
              {isOpen ? (
                <KeyboardArrowUpIcon sx={{ fontSize: '3.5rem' }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ fontSize: '3.5rem' }} />
              )}
            </IconButton>
          )}
          <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            {data.name}
          </span>
        </TableCell>
        <StyledTableCell>
          {data.marketValue
            ? formatNumber(data.marketValue, NumberFormatType.Currency, 0, 0)
            : '--'}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('MTD', 'monthToDate')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('QTD', 'quarterToDate')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('YTD', 'yearToDate')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('1YR', 'oneYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('3YR', 'threeYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('5YR', 'fiveYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('10YR', 'tenYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('Since Inception', 'sinceInception')}
        </StyledTableCell>
        <StyledInceptionTableCell></StyledInceptionTableCell>
      </TableRow>
      {sort(data.nestedData).map((rowData) => {
        let startOpen = false;
        return (
          <PerformanceTableRow
            key={rowData.uniqueId}
            rowData={rowData}
            startOpen={startOpen}
            isOpen={isOpen}
            visibilityState={visibilityState}
          />
        );
      })}
    </>
  );
};
