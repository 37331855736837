import { MobileReportTable } from '@newedge/common';
import { HouseholdTableRowData } from '../@types';
import PerformanceTable from '../performance-table/PerformanceTable';
import { mobileReportColumns } from '.';

interface SubAssetClassPanelContentProps {
  subAssetClassPerformanceData: HouseholdTableRowData[];
  setHeadCellWidths: React.Dispatch<React.SetStateAction<{}>>;
}

export const SubAssetClassPanelContent = ({
  subAssetClassPerformanceData,
  setHeadCellWidths,
}: SubAssetClassPanelContentProps) => {
  return (
    <PerformanceTable
      data={subAssetClassPerformanceData}
      setHeadCellWidths={setHeadCellWidths}
    />
  );
};

interface SubAssetClassMobileTableProps {
  subAssetClassPerformanceData: HouseholdTableRowData[];
}

export const SubAssetClassMobileTable = ({
  subAssetClassPerformanceData,
}: SubAssetClassMobileTableProps) => {
  return (
    <MobileReportTable
      columns={mobileReportColumns}
      data={subAssetClassPerformanceData}
      headerId='name'
      nestedHeaderId='name'
      nestedAccessor={(rowData) => rowData.nestedData!}
      customTileListRenderer={(nestedData) => {
        return (
          <MobileReportTable
            columns={mobileReportColumns}
            data={nestedData}
            headerId='name'
            nestedHeaderId='name'
            nestedAccessor={(rowData) => rowData.nestedData!}
          />
        );
      }}
      hasParentHeaders={false}
      positiveNumberDecorator={true}
    />
  );
};
